import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image_mobile = require("../../../assets/img/blogs/llm_evaluation_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/llm_evaluation_blog_header.png");
const section_1 = require("../../../assets/img/blogs/llm_evaluation_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/llm_evaluation_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/llm_evaluation_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/llm_evaluation_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/llm_evaluation_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/llm_evaluation_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/llm_evaluation_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/llm_evaluation_blog_img_8.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="Evaluate LLM for Enterprise User Support"
        description="LLM evaluation is a comprehensive process for assessing the capabilities and functionalities of large language models. Here is what you need to consider for the LLM eval."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt llm_evaluation_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger  color-black line-height-18">
                    Criterias to Evaluate LLM for Enterprise User Support
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    Criterias to Evaluate
                    <br /> LLM for Enterprise
                    <br /> User Support
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is LLM Evaluation?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why is LLM evaluation needed?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Importance of building the right LLM evals for support use
                  cases
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Critical considerations in evaluating LLMs for enterprise
                  support applications
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. LLM evaluations vary by use case
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-fact-check">
                  ChatGPT
                </a>{" "}
                is a widely popular{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  chat interface
                </a>{" "}
                for everyone now for all the right reasons.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It can generate new texts, translate multiple languages, perform
                NLP tasks, and reduce human workloads by lowering what is
                repetitive for humans.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, what unexpectedly gained a lot of traction for ChatGPT is
                the underlying technology—{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search">
                  LLM or large language model.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs are a new attraction for enterprise leaders to drive
                massive AI transformation to elevate the pace of efficiency and
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, ethical concerns can arise, as seen with the existing
                off-the-shelf platforms, such as pre-trained models, ChatGPT,
                Gemini, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs with data with a specific threshold period can lack the
                current world of knowledge, limiting specificity and causing
                hallucinations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, applications built over off-the-shelf LLM models can be
                risky.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, new large language models built with new data
                can also hallucinate and produce biased behavior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM evaluation is highly significant in ensuring trust and
                credibility.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Evaluating LLMs is overwhelming work and should encompass
                multiple assessment procedures.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is essential to find the best ways to evaluate large language
                models and harness the correct data set for model training and
                assessment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, how can we assess LLM for enterprise? Let’s know the
                best ways we can evaluate large language models.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is LLM Evaluation?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                LLM evaluation, known as Evals in short form, is a comprehensive
                process to assess the capabilities and functionalities of large
                language models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM evals can also be considered as metrics or measurements to
                assess the performance of LLMs or LLM-built applications.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Model evaluation primarily helps understand the
                product-readiness of an LLM application or model.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An evaluation process is effective in identifying loopholes
                rather than weaknesses. A robust LLM evaluation process helps
                you assess how your application can interact with end users in
                real-world scenarios and offer the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-customer-support">
                  best user experience.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is LLM evaluation needed?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Industry leaders are aware of the biases or other{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  threats of LLMs.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM evals make it easy to flag anything that might show negative
                behavior. As a result, LLM evaluation can help us improve model
                performance and continue to produce a system to works to its
                best capacity.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                To further highlight how an LLM eval can look, we can explain
                this way,
              </p>

              <ul class="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Evals ensure LLM works well. Evals are like grading systems
                    to evaluate whether an LLM learns to respond well and
                    understand mistakes.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    LLM evals produce a better model. The continuous large
                    language model evaluation can help us reveal where a model
                    is struggling and provide data-driven suggestions to teach
                    LLMs to act smarter.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Evals can prevent safety concerns. A robust assessment
                    process can help us identify how a model can go wrong, fix
                    it, and use it for better use cases they are good at.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Importance of building the right LLM evals for support use cases
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs allow multiple use cases depending on their text generation
                and language understanding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the leading use cases is{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  AI chatbots,
                </a>{" "}
                which simplify language processing and understanding for better
                delivery of responses and problem resolution.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Within this context, if a conversational AI application does not
                integrate an LLM model evaluated comprehensively for its varied
                use cases, it erroneously impact user experiences by delivering
                wrong responses or exhibiting biased behavior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is essential to comprehensively check LLM applications or
                models before being put to use.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Critical considerations in evaluating LLMs for enterprise
                support applications
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt=" Large language model evaluation method considerations "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                LLM evaluation must encompass assessment metrics to ensure that
                models or applications deliver expected services without a minor
                glitch. This is essentially important if you want to implement
                LLM models for your customer service use cases or internal
                employee support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let us know what you need to consider—
              </p>

              <h3 className="font-section-sub-header-small">Model accuracy</h3>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt=" Large language model accuracy considerations"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                LLM evaluation must confirm that the produced outcome is
                accurate in accordance with query inputs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It means The LLM model must have the ability to understand
                inputs and deliver answers accurately.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a customer raises a query for refund status, an
                LLM-powered chat interface must understand the user’s query and
                provide an accurate answer. So, LLM evaluation helps assess
                every answer against a set of questions or domain-specific
                scenarios.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With that, LLM evaluation can also help detect inaccuracies, put
                them back to correction, and ensure accuracy.
              </p>
              <h3 className="font-section-sub-header-small">Model fluency</h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="LLM evaluation consideration includes grammatical comprehension "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is a significant criterion for LLM evaluation that ensures
                an LLM model can comprehend proper grammatical aspects—sentence
                construction, spelling correctness, syntax understanding, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This capability helps LLM models generate grammatically correct
                texts to improve answer acceptance and encourage users to seek
                help from a Generative AI-based chat platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In this scenario, we can also assume that if a user sends
                queries in a fragmented way, an LLM model can still recognize
                the query and provide correct responses by following the fluency
                of language.
              </p>
              <h3 className="font-section-sub-header-small">
                LLM model precision and relevance
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Large language model precision and consistency                  "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The evaluation method for large language models must ensure
                response precision and consistency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A query can mean varied contexts. LLM evaluation must ensure
                that LLM models or applications can understand the context a
                user wants to convey and provide a relevant and consistent
                answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a user asks, ‘How do I reset my password’? This
                question can involve any type of device requiring password reset
                help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                An LLM model must follow predefined steps to help users in this
                scenario.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                There can be straightforward instructions from a chatbot if a
                user needs help with an enterprise productivity app. On the
                other hand, if it is about resetting passwords for the corporate
                network, an LLM app can ask to connect with the human agent at
                the help desk or IT department.
              </p>
              <h3 className="font-section-sub-header-small">
                LLM model consistency
              </h3>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" Large language model evaluation - consistency   "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Consistent responses are highly desirable with LLM-powered
                chatbots across customer or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/knowledge-ai-employee-support">
                  employee support.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If a model generates inconsistent responses all across its
                generated texts, it can impact problem-solving and confusion and
                increase workloads for human agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The evaluation criterion for LLM must involve assessing the
                consistency level of any LLM model to improve user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say a user asks a travel assistant bot a query about interesting
                sightseeing in Paris. LLM evals must ensure that it provides
                responses around destinations and key attractions only in Paris
                rather than generating answers about other cities in France.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, if a user wants to verify a question by providing a wrong
                response, the LLM model must correct the user in the follow-up
                question and maintain consistency.
              </p>
              <h3 className="font-section-sub-header-small">
                Scalability and performance
              </h3>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="scalability is LLM’s evaluation consideration                 "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ticket influx can be a usual scenario across the customer or
                employee support ecosystem in a holiday or festive season, where
                users want answers for shopping issues. On the other way around,
                IT must work in tandem to provide excellent uptime for devices
                and applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Both these scenarios demand an LLM model to handle the bandwidth
                of questions and deliver responses quickly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                An LLM evaluation process also includes assessing an LLM model's
                scalability and performance capability so that users can easily
                have seamless response and problem-solving flexibility.
              </p>
              <h3 className="font-section-sub-header-small">
                Adaptability to specific enterprise needs and terminology
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt=" Large language model enables industry-specific response delivery  "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI-powered{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  conversational AI
                </span>{" "}
                can easily adapt to any business function and industry.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, the problem is that every industry has different
                terminology that is significant for users as they ask questions.
                If models lack knowledge about these terminologies, they aren’t
                useful for industry-specific use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As part of your LLM evaluation process, it is essential to
                ensure that LLM models follow proper training datasets that
                explain industry-specific terminology and facilitate response
                delivery.
              </p>
              <h3 className="font-section-sub-header-small">
                Compatibility with existing IT infrastructure and systems
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Industry leaders aim to streamline business process workflows,
                gain efficiency, and maximize productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI applications must provide API integration to allow
                existing IT systems, such as CRM, ERP, ITSM, or service desk
                platforms, to{" "}
                <a href="https://blog.workativ.com/workflow-automation-definition-how-to-and-examples-workativ/">
                  automate workflows.{" "}
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is a critical part of the LLM evaluation job to ensure that
                LLM applications are perfectly compatible with existing systems,
                help communicate with each other, and facilitate automation of
                desired workflows.
              </p>
              <h3 className="font-section-sub-header-small">
                Security and bias detection and mitigation
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Bias mitigation as LLM evaluation consideration "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Bias detection is part of LLM evaluation, which is critical to
                identifying the tendencies of models to produce prejudiced
                responses and create bias.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a particular training data includes
                conversations or messages that showcase banter or hatred toward
                a specific community or gender, a response or outcome can be
                biased.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                So, LLM evaluation must carefully flag these propensities in
                training data to improve veracity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Similarly, it is also essential to observe that LLM applications
                give accurate and relevant information rather than generating
                misinformation, false or misleading information that can cause
                security threats.
              </p>
              <h3 className="font-section-sub-header-small">
                Compliance with industry regulations and standards
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                GDPR, FTC, and other regulatory bodies provide details about
                maintaining compliance with industry standards and preventing
                security risks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                LLM evaluations must follow strict criteria that adhere to these
                regulatory mechanisms.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                LLM evaluations vary by use case
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s understand that one-size-fits-all cannot be appropriate
                for LLM evaluations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Depending on the various use cases, evaluations must encompass
                custom requirements or unique demands of specific scenarios to
                evaluate the performance and veracity of an LLM model.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if an LLM model is built for the medical domain, it
                must follow evaluation metrics related to the clinical
                ecosystem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Response relevance allows patients to have clear conversations.
                Accuracy in question and answer is vital for patients to derive
                appropriate answers regarding any disease and illness.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, if a model has to take care of the service
                desk, the evaluation process includes metrics for service desk
                cases such as password reset, account unlock, etc.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Evaluating large language models is necessary for every industry
                leader to consider. LLM evaluations are essential for gauging
                performance, capabilities, and weaknesses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Executing these evals can help you prevent risks to the models
                and ensure model veracity for maximum user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, LLM evals can look challenging depending on various use
                cases. We’ve put up a list of considerations for LLM evaluation.
                They can be effective for your specific use case and allow you
                to prevent business risks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that, if you want to get started in no time and experience
                LLM transformation for your service desk or ITSM,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  book a demo with Workativ.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is LLM Evaluation?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Why is LLM evaluation needed?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Importance of building the right LLM evals for support
                    use cases
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Critical considerations in evaluating LLMs for enterprise
                    support applications
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. LLM evaluations vary by use case
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-fact-check">
                  ChatGPT
                </a>{" "}
                is a widely popular{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  chat interface
                </a>{" "}
                for everyone now for all the right reasons.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It can generate new texts, translate multiple languages, perform
                NLP tasks, and reduce human workloads by lowering what is
                repetitive for humans.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, what unexpectedly gained a lot of traction for ChatGPT is
                the underlying technology—{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search">
                  LLM or large language model.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs are a new attraction for enterprise leaders to drive
                massive AI transformation to elevate the pace of efficiency and
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, ethical concerns can arise, as seen with the existing
                off-the-shelf platforms, such as pre-trained models, ChatGPT,
                Gemini, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs with data with a specific threshold period can lack the
                current world of knowledge, limiting specificity and causing
                hallucinations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, applications built over off-the-shelf LLM models can be
                risky.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, new large language models built with new data
                can also hallucinate and produce biased behavior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM evaluation is highly significant in ensuring trust and
                credibility.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Evaluating LLMs is overwhelming work and should encompass
                multiple assessment procedures.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is essential to find the best ways to evaluate large language
                models and harness the correct data set for model training and
                assessment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, how can we assess LLM for enterprise? Let’s know the
                best ways we can evaluate large language models.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is LLM Evaluation?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                LLM evaluation, known as Evals in short form, is a comprehensive
                process to assess the capabilities and functionalities of large
                language models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM evals can also be considered as metrics or measurements to
                assess the performance of LLMs or LLM-built applications.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Model evaluation primarily helps understand the
                product-readiness of an LLM application or model.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An evaluation process is effective in identifying loopholes
                rather than weaknesses. A robust LLM evaluation process helps
                you assess how your application can interact with end users in
                real-world scenarios and offer the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-customer-support">
                  best user experience.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is LLM evaluation needed?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Industry leaders are aware of the biases or other{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  threats of LLMs.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM evals make it easy to flag anything that might show negative
                behavior. As a result, LLM evaluation can help us improve model
                performance and continue to produce a system to works to its
                best capacity.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                To further highlight how an LLM eval can look, we can explain
                this way,
              </p>

              <ul class="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Evals ensure LLM works well. Evals are like grading systems
                    to evaluate whether an LLM learns to respond well and
                    understand mistakes.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    LLM evals produce a better model. The continuous large
                    language model evaluation can help us reveal where a model
                    is struggling and provide data-driven suggestions to teach
                    LLMs to act smarter.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Evals can prevent safety concerns. A robust assessment
                    process can help us identify how a model can go wrong, fix
                    it, and use it for better use cases they are good at.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Importance of building the right LLM evals for support use cases
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs allow multiple use cases depending on their text generation
                and language understanding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the leading use cases is{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  AI chatbots,
                </a>{" "}
                which simplify language processing and understanding for better
                delivery of responses and problem resolution.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Within this context, if a conversational AI application does not
                integrate an LLM model evaluated comprehensively for its varied
                use cases, it erroneously impact user experiences by delivering
                wrong responses or exhibiting biased behavior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is essential to comprehensively check LLM applications or
                models before being put to use.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Critical considerations in evaluating LLMs for enterprise
                support applications
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt=" Large language model evaluation method considerations "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                LLM evaluation must encompass assessment metrics to ensure that
                models or applications deliver expected services without a minor
                glitch. This is essentially important if you want to implement
                LLM models for your customer service use cases or internal
                employee support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let us know what you need to consider—
              </p>

              <h3 className="font-section-sub-header-small">Model accuracy</h3>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt=" Large language model accuracy considerations"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                LLM evaluation must confirm that the produced outcome is
                accurate in accordance with query inputs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It means The LLM model must have the ability to understand
                inputs and deliver answers accurately.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a customer raises a query for refund status, an
                LLM-powered chat interface must understand the user’s query and
                provide an accurate answer. So, LLM evaluation helps assess
                every answer against a set of questions or domain-specific
                scenarios.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With that, LLM evaluation can also help detect inaccuracies, put
                them back to correction, and ensure accuracy.
              </p>
              <h3 className="font-section-sub-header-small">Model fluency</h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="LLM evaluation consideration includes grammatical comprehension "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is a significant criterion for LLM evaluation that ensures
                an LLM model can comprehend proper grammatical aspects—sentence
                construction, spelling correctness, syntax understanding, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This capability helps LLM models generate grammatically correct
                texts to improve answer acceptance and encourage users to seek
                help from a Generative AI-based chat platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In this scenario, we can also assume that if a user sends
                queries in a fragmented way, an LLM model can still recognize
                the query and provide correct responses by following the fluency
                of language.
              </p>
              <h3 className="font-section-sub-header-small">
                LLM model precision and relevance
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Large language model precision and consistency                  "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The evaluation method for large language models must ensure
                response precision and consistency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A query can mean varied contexts. LLM evaluation must ensure
                that LLM models or applications can understand the context a
                user wants to convey and provide a relevant and consistent
                answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a user asks, ‘How do I reset my password’? This
                question can involve any type of device requiring password reset
                help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                An LLM model must follow predefined steps to help users in this
                scenario.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                There can be straightforward instructions from a chatbot if a
                user needs help with an enterprise productivity app. On the
                other hand, if it is about resetting passwords for the corporate
                network, an LLM app can ask to connect with the human agent at
                the help desk or IT department.
              </p>
              <h3 className="font-section-sub-header-small">
                LLM model consistency
              </h3>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" Large language model evaluation - consistency   "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Consistent responses are highly desirable with LLM-powered
                chatbots across customer or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/knowledge-ai-employee-support">
                  employee support.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If a model generates inconsistent responses all across its
                generated texts, it can impact problem-solving and confusion and
                increase workloads for human agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The evaluation criterion for LLM must involve assessing the
                consistency level of any LLM model to improve user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say a user asks a travel assistant bot a query about interesting
                sightseeing in Paris. LLM evals must ensure that it provides
                responses around destinations and key attractions only in Paris
                rather than generating answers about other cities in France.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, if a user wants to verify a question by providing a wrong
                response, the LLM model must correct the user in the follow-up
                question and maintain consistency.
              </p>
              <h3 className="font-section-sub-header-small">
                Scalability and performance
              </h3>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="scalability is LLM’s evaluation consideration                 "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ticket influx can be a usual scenario across the customer or
                employee support ecosystem in a holiday or festive season, where
                users want answers for shopping issues. On the other way around,
                IT must work in tandem to provide excellent uptime for devices
                and applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Both these scenarios demand an LLM model to handle the bandwidth
                of questions and deliver responses quickly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                An LLM evaluation process also includes assessing an LLM model's
                scalability and performance capability so that users can easily
                have seamless response and problem-solving flexibility.
              </p>
              <h3 className="font-section-sub-header-small">
                Adaptability to specific enterprise needs and terminology
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt=" Large language model enables industry-specific response delivery  "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI-powered{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  conversational AI
                </span>{" "}
                can easily adapt to any business function and industry.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, the problem is that every industry has different
                terminology that is significant for users as they ask questions.
                If models lack knowledge about these terminologies, they aren’t
                useful for industry-specific use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As part of your LLM evaluation process, it is essential to
                ensure that LLM models follow proper training datasets that
                explain industry-specific terminology and facilitate response
                delivery.
              </p>
              <h3 className="font-section-sub-header-small">
                Compatibility with existing IT infrastructure and systems
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Industry leaders aim to streamline business process workflows,
                gain efficiency, and maximize productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI applications must provide API integration to allow
                existing IT systems, such as CRM, ERP, ITSM, or service desk
                platforms, to{" "}
                <a href="https://blog.workativ.com/workflow-automation-definition-how-to-and-examples-workativ/">
                  automate workflows.{" "}
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is a critical part of the LLM evaluation job to ensure that
                LLM applications are perfectly compatible with existing systems,
                help communicate with each other, and facilitate automation of
                desired workflows.
              </p>
              <h3 className="font-section-sub-header-small">
                Security and bias detection and mitigation
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Bias mitigation as LLM evaluation consideration "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Bias detection is part of LLM evaluation, which is critical to
                identifying the tendencies of models to produce prejudiced
                responses and create bias.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a particular training data includes
                conversations or messages that showcase banter or hatred toward
                a specific community or gender, a response or outcome can be
                biased.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                So, LLM evaluation must carefully flag these propensities in
                training data to improve veracity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Similarly, it is also essential to observe that LLM applications
                give accurate and relevant information rather than generating
                misinformation, false or misleading information that can cause
                security threats.
              </p>
              <h3 className="font-section-sub-header-small">
                Compliance with industry regulations and standards
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                GDPR, FTC, and other regulatory bodies provide details about
                maintaining compliance with industry standards and preventing
                security risks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                LLM evaluations must follow strict criteria that adhere to these
                regulatory mechanisms.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                LLM evaluations vary by use case
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s understand that one-size-fits-all cannot be appropriate
                for LLM evaluations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Depending on the various use cases, evaluations must encompass
                custom requirements or unique demands of specific scenarios to
                evaluate the performance and veracity of an LLM model.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if an LLM model is built for the medical domain, it
                must follow evaluation metrics related to the clinical
                ecosystem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Response relevance allows patients to have clear conversations.
                Accuracy in question and answer is vital for patients to derive
                appropriate answers regarding any disease and illness.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, if a model has to take care of the service
                desk, the evaluation process includes metrics for service desk
                cases such as password reset, account unlock, etc.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Evaluating large language models is necessary for every industry
                leader to consider. LLM evaluations are essential for gauging
                performance, capabilities, and weaknesses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Executing these evals can help you prevent risks to the models
                and ensure model veracity for maximum user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, LLM evals can look challenging depending on various use
                cases. We’ve put up a list of considerations for LLM evaluation.
                They can be effective for your specific use case and allow you
                to prevent business risks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that, if you want to get started in no time and experience
                LLM transformation for your service desk or ITSM,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  book a demo with Workativ.
                </a>
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-trends-2024"
              className="font-section-normal-text-testimonials"
            >
              Top ITSM Trends in 2024
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/ai-co-pilot-it-operations
              "
              className="font-section-normal-text-testimonials"
            >
              The Role of AI Co-pilots in IT Operations
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/onboarding-challenges-it-service-desk-face-with-new-agents"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Onboarding Challenges IT Service Desk Face with New Agents
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
